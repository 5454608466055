import React, { useState, useEffect } from "react";
import { collection, getDocs, updateDoc, doc, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
// import { DataGrid } from "@mui/x-data-grid";
import "./rigchecksList.scss";
import { useParams } from "react-router-dom";
import SingleRigcheck from "../../components/singleRigcheck/SingleRigcheck";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { onSnapshot } from "firebase/firestore";

const RigchecksList = () => {
  const [checks, setChecks] = useState([]);
  let { agency } = useParams();

  // format agency to capitalize first letter of each word
  agency = agency.replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  );

  // const agency = "Terryville";

  const handleChange = (event) => {
    console.log(event.target.value);
  };

  useEffect(() => {
    const getChecks = async () => {
      const agencyRigchecksCollectionRef = collection(
        db,
        "Rigchecks/VgTv80ik2BtavT0A5owc/" + agency + " Rigchecks"
      );
    
      let queryRigchecks = query(agencyRigchecksCollectionRef, orderBy("order", "desc"));

      const querySnapshot = await getDocs(queryRigchecks);
      let unsubscribe;
      if (querySnapshot.size > 0) {
        // Subscribe to the ordered collection
        unsubscribe = onSnapshot(queryRigchecks, (snapshot) => {
          // Improvement: filtering could be done in the query with where clause if migration to give each rigcheck a disabled field is done
          const list = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })).filter(check => !check.disabled);
          setChecks(list);
        });
      }
    
      // Cleanup the listener when the component is unmounted
      return () => unsubscribe();
    }

    getChecks();
  }, [agency]);

  return (
    <div
      style={{ backgroundColor: "#606060", height: "100%", minHeight: "100vh" }}
    >
      <div className="schedule-header">
        <div className="schedule-title">{agency} Rigchecks</div>
        {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            value={'age'}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl> */}
      </div>
      <div className="checks-container">
        {checks.map((check) => (
          <SingleRigcheck key={check.id} check={check} />
        ))}
      </div>
    </div>
  );
};

export default RigchecksList;