import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  collection,
  getDoc,
  doc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import { ReactFormGenerator, Registry } from "react-form-builder2";
import "./answerRigcheck.scss";
import { RotatingLines } from "react-loader-spinner";
import "react-form-builder2/dist/app.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PassFailComponent } from "./passFailComponent";
import { CommentComponent } from "./commentComponent";
import { SealComponent } from "./sealComponent";
import { ReplaceQuestionComponent } from "./replaceQuestionComponent";
import { SealedBrokenComponent } from "./sealedBrokenComponent";
import { SealNumberComponent } from "./sealNumberComponent";
import { NewSealNumberComponent } from "./newSealNumberComponent";

const AnswerRigcheck = () => {
  const [checkData, setCheckData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { agency } = useParams();
  const containerRef = useRef(null);
  const { checkId } = useParams();
  
  const navigate = useNavigate();

  const handleRadioChange = () => {
    const sealedRadio = containerRef.current.querySelectorAll('input[type="radio"][value="sealed"]');
    const brokenRadio = containerRef.current.querySelectorAll('input[type="radio"][value="broken"]');
    const noRadio = containerRef.current.querySelectorAll('input[type="radio"][value="no"]');
    const yesRadio = containerRef.current.querySelectorAll('input[type="radio"][value="yes"]');

    sealedRadio.forEach(radio => {
      radio.addEventListener('change', (e) => {
        if (e.target.checked) {
          const replaceDiv = radio.closest('.SortableItem').nextElementSibling.querySelector('.replace');
          replaceDiv.style.display = 'none';
        }

        // if one sibling up is has a seal-number-input and the value is empty, change the display of the .new-seal-number div from two sibings down
        const sealNumber = radio.closest('.SortableItem').previousElementSibling.querySelector('.seal-number');
        if (sealNumber.querySelector('input').value === '') {
          const replaceQuestionSortableItem = radio.closest('.SortableItem').nextElementSibling;
          const newSealNumberDiv = replaceQuestionSortableItem.closest('.SortableItem').nextElementSibling.querySelector('.new-seal-number');
          newSealNumberDiv.style.display = 'block';
        }
      });
    });

    brokenRadio.forEach(radio => {
      radio.addEventListener('change', (e) => {
        if (e.target.checked) {
          const replaceDiv = radio.closest('.SortableItem').nextElementSibling.querySelector('.replace');
          replaceDiv.style.display = 'block';
        }      
        
        // if one sibling up is has a seal-number-input and the value is empty, hide the .new-seal-number div from two sibings down
        // Unless yes is checked in the replace question.
        const sealNumber = radio.closest('.SortableItem').previousElementSibling.querySelector('.seal-number');
        if (sealNumber.querySelector('input').value === '') {
          const replaceQuestionSortableItem = radio.closest('.SortableItem').nextElementSibling;
          const replaceQuestionRadio = replaceQuestionSortableItem.querySelector('input[type="radio"][value="yes"]');
          if (replaceQuestionRadio.checked) {
            console.log('yes is checked');
          } else {
            const newSealNumberDiv = replaceQuestionSortableItem.closest('.SortableItem').nextElementSibling.querySelector('.new-seal-number');
            newSealNumberDiv.style.display = 'none';
          }
        }
      });
    });

    noRadio.forEach(radio => {
      radio.addEventListener('change', (e) => {
        if (e.target.checked) {
          const replaceDiv = radio.closest('.SortableItem').nextElementSibling.querySelector('.new-seal-number');
          replaceDiv.style.display = 'none';
        }
      });
    });

    yesRadio.forEach(radio => {
      radio.addEventListener('change', (e) => {
        if (e.target.checked) {
          const replaceDiv = radio.closest('.SortableItem').nextElementSibling.querySelector('.new-seal-number');
          replaceDiv.style.display = 'block';
        }
      });
    });
  };

  useEffect(() => {
    if (!loading) {
      // query all elements with class seal-number-input
      const sealNumberInputs = document.querySelectorAll('.seal-number-input');
      const sealNumbers = checkData.sealNumbers;
      // loop through all elements with class seal-number-input and set the value to the corresponding value in sealNumbers. Its corresponding is based on index.
      if (sealNumbers && sealNumbers.length) {
        sealNumberInputs.forEach((input, index) => {
          input.value = sealNumbers[index] ? sealNumbers[index].value || '' : '';
        });
      } else {
        sealNumberInputs.forEach((input) => {
          input.value = '';
        });
      }

      handleRadioChange();
    }
  }, [loading, checkData]);

  const handleSubmit = async (data) => {
    // handle validation for new seal number
    const sortableItems = document.querySelectorAll('.SortableItem');

    for (let i = 0; i < sortableItems.length; i++) {
      const item = sortableItems[i];
      const inputs = item.querySelectorAll('input');
      const nextItem = sortableItems[i + 1];
      if (nextItem) {
        const sealNumberInput = nextItem.querySelector('.new-seal-number-input');
        if (inputs.length && inputs[0].value === 'yes' && inputs[0].checked && sealNumberInput && !sealNumberInput.value) {
          toast.warn("Please enter a new seal number", {
              position: "top-center",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
          return; // Exit the handleSubmit function early if validation fails
        }
      }
    }

    setIsSubmitting(true);
    const colRef = collection(
      db,
      `RigcheckAnswers/OdtRgRZbYmEnNipNIft7/${agency} Rigchecks Answers`
    );
    data.forEach((dataEle, dataIndex) => {
      let ele = document.getElementsByName(dataEle.custom_name);
      ele.forEach((el) => {
        if (el.checked) {
          // Assuming dataEle has a property called 'value', update it with el.value
          data[dataIndex].value = el.value;
        }
      });
    });

    try {
      const docRef = await addDoc(colRef, {
        answerData: data,
        createdAt: serverTimestamp(),
        rigcheckName: checkData.name,
        rigcheckId: checkId,
      });
      toast.success("Form Saved Successfully", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate(`/${agency}`);
    } catch (err) {
      toast.warn("Error Saving Form", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchRigcheck = async () => {
      const docRef = doc(
        db,
        "Rigchecks",
        "VgTv80ik2BtavT0A5owc",
        agency + " Rigchecks",
        checkId
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const docData = docSnap.data();
        // const updatedForm = updateForm(docSnap.data().form);
        // docData.form = updatedForm;
        setCheckData(docData);
      }
      setLoading(false);
    };
    fetchRigcheck();
  }, []);

  try {
    Registry.register("PassFailComponent", PassFailComponent);
    Registry.register("CommentComponent", CommentComponent);
    Registry.register("SealComponent", SealComponent);
    Registry.register("ReplaceQuestionComponent", ReplaceQuestionComponent);
    Registry.register("SealedBrokenComponent", SealedBrokenComponent);
    Registry.register("SealNumberComponent", SealNumberComponent);
    Registry.register("NewSealNumberComponent", NewSealNumberComponent);

  } catch (error) {}
  return (
    <div>
      <Helmet>
        {" "}
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        />{" "}
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.13.0/css/all.css"
        />{" "}
      </Helmet>{" "}
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "100vh" }}
        >
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={true}
          />
        </div>
      ) : (
        <div className="background-container">
          <div className="form-generator-container" ref={containerRef}>
            <div className="form-title">{checkData.name}</div>
            <div className="required-text">
              Fields marked with an <span style={{ color: "red" }}>*</span> are
              required
            </div>
            <ReactFormGenerator
              data={JSON.parse(checkData.form)}
              back_action={`/${agency}`}
              back_name="Back"
              onSubmit={handleSubmit}
              submitButton={
                <button type="submit" className="btn" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AnswerRigcheck;