import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RigchecksList from "./pages/rigchecksList/RigchecksList";
import AnswerRigcheck from "./pages/answerRigcheck/AnswerRigcheck";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path=":agency">
              <Route index element={<RigchecksList />} />

              <Route path=":checkId" element={<AnswerRigcheck />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
