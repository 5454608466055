import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

export const PassFailComponent = React.forwardRef((props, ref) => {
  const { name, defaultValue, onChange } = props;

  const [selectedOption, setSelectedOption] = useState(
    defaultValue || props.default || ""
  );
  const generateUniqueId = () => {
    const baseId = "fid_preview_radiobuttons_option_";
    const uniqueId = new Date().getTime().toString(36);
    return `${baseId}${uniqueId}`;
  };

  const uniqueId = generateUniqueId();

  const passId = `passOption_${Math.random().toString(36).substr(2, 9)}`;
  const failId = `failOption_${Math.random().toString(36).substr(2, 9)}`;

  const passRef = useRef(null);
  const failRef = useRef(null);

  // useLayoutEffect to ensure refs are available before rendering
  useLayoutEffect(() => {
    // if (passRef.current) {
    //   passRef.current.id = `passOption_${Math.random()
    //     .toString(36)
    //     .substr(2, 9)}`;
    // }
    // if (failRef.current) {
    //   failRef.current.id = `failOption_${Math.random()
    //     .toString(36)
    //     .substr(2, 9)}`;
    // }

    if (ref && (props.default === "pass" || props.default === "fail")) {
      ref.current = passRef.current; // Assigning the DOM element to the forwarded ref
    }
  }, []);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    ref.current = event.target;
    setSelectedOption(value);

    if (onChange) {
      onChange(name, value);
    }
  };

  const formatLabel = (label) => {
    return label.replace(/<strong>/g, '').replace(/<\/strong>/g, '').replace(/</g, '&lt;').replace(/>/g, '&gt;');
};

  return (
    <div className="form-group">
      <label className="form-label">
        <span>
          <span dangerouslySetInnerHTML={{ __html: formatLabel(props.data.label) }}></span>
          {props.data.required ? (
            <span style={{ color: "red" }}>*</span>
          ) : (
            <></>
          )}
        </span>
      </label>

      <div className="custom-control custom-radio-passfail option-inline">
        <input
          className="custom-control-input"
          name={name}
          type="radio"
          value="pass"
          defaultValue={defaultValue}
          checked={selectedOption === "pass"}
          onChange={handleOptionChange}
          id={passId}
          ref={passRef}
        />
        <label className="custom-control-label-passfail" htmlFor={passId}>
          Pass
        </label>
      </div>
      <div className="custom-control custom-radio-passfail option-inline">
        <input
          className="custom-control-input"
          name={name}
          type="radio"
          value="fail"
          defaultValue={defaultValue}
          checked={selectedOption === "fail"}
          onChange={handleOptionChange}
          id={failId}
          ref={failRef}
        />
        <label className="custom-control-label-passfail" htmlFor={failId}>
          Fail
        </label>
      </div>
    </div>
  );
});