import { useParams } from "react-router-dom";
import "./singleRigcheck.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { onSnapshot } from "firebase/firestore";

const SingleRigcheck = ({ check }) => {
  const navigate = useNavigate();
  let { agency } = useParams();
  agency = agency.replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  );
  // test data
  const disabledService = check.disabledService;
  const [failedValue, setFailedValue] = useState([]);
  const [completedToday, setCompletedToday] = useState([]);

  const handleCardClick = () => {
    navigate(`/${agency}/${check.id}`);
  };

  // const findPassOrFailItem = (answerData) =>
  //   answerData.find(
  //     (item) =>
  //       Array.isArray(item.value) &&
  //       item.value.length === 1 &&
  //       (item.value[0].toLowerCase() === "pass" ||
  //         item.value[0].toLowerCase() === "fail")
  //   );

  useEffect(() => {
    async function subscribeToRigcheckAnswers() {
      const fortyEightHoursAgo = new Date();
      fortyEightHoursAgo.setHours(fortyEightHoursAgo.getHours() - 48);

      try {
        const response = await fetch(
          "https://us-central1-ladderemsrn.cloudfunctions.net/getcheckscompleted",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              agency: agency,
              check: check,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();

        const checksCompletedToday = responseData.checksCompletedToday || [];
        const failedChecks = responseData.failedChecks || 0;

        setCompletedToday(checksCompletedToday.length);
        setFailedValue(failedChecks);
      } catch (error) {
        console.error("Error fetching checks:", error);
      }
    }

    subscribeToRigcheckAnswers();
  }, [agency, check.id]);

  return (
    <div className="rigcheck-card" onClick={handleCardClick}>
      <div className="header">
        <h1>{check.name}</h1>
        <div className="flex-row">
          <div
            className="icon-square"
            style={{ background: disabledService ? "red" : "green" }}
          >
            {disabledService ? "OUT OF SERVICE" : "IN SERVICE"}
          </div>
        </div>
      </div>
      <div className="details">
        <div className="detail-column">
          <div
            className="item-count"
            style={{ color: completedToday ? "white" : "red" }}
          >
            {completedToday}
          </div>
          <div className="text-description">Checks Completed Today</div>
        </div>
        <div className="detail-column">
          <div
            className="item-count"
            style={{ color: failedValue ? "#f65224" : "green" }}
          >
            {failedValue}
          </div>
          <div className="text-description">Failed</div>
        </div>
      </div>
    </div>
  );
};

export default SingleRigcheck;
