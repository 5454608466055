import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

export const SealedBrokenComponent = React.forwardRef((props, ref) => {
  const { name, defaultValue, onChange } = props;

  const [selectedOption, setSelectedOption] = useState(
    defaultValue || props.default || ""
  );
  const generateUniqueId = () => {
    const baseId = "fid_preview_radiobuttons_option_";
    const uniqueId = new Date().getTime().toString(36);
    return `${baseId}${uniqueId}`;
  };

  const uniqueId = generateUniqueId();

  const sealedId = `passOption_${Math.random().toString(36).substr(2, 9)}`;
  const brokenId = `failOption_${Math.random().toString(36).substr(2, 9)}`;

  const sealedRef = useRef(null);
  const brokenRef = useRef(null);

  // useLayoutEffect to ensure refs are available before rendering
  useLayoutEffect(() => {
    // if (passRef.current) {
    //   passRef.current.id = `passOption_${Math.random()
    //     .toString(36)
    //     .substr(2, 9)}`;
    // }
    // if (failRef.current) {
    //   failRef.current.id = `failOption_${Math.random()
    //     .toString(36)
    //     .substr(2, 9)}`;
    // }

    if (ref && (props.default === "sealed" || props.default === "broken")) {
      ref.current = sealedRef.current; // Assigning the DOM element to the forwarded ref
    }
  }, []);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    ref.current = event.target;
    setSelectedOption(value);

    if (onChange) {
      onChange(name, value);
    }
  };

  const formatLabel = (label) => {
    return label.replace(/<strong>/g, '').replace(/<\/strong>/g, '').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    };

  return (
    <div className="form-group sealed-broken">
      <div className="custom-control custom-radio-passfail option-inline">
        <input
          className="custom-control-input"
          name={name}
          type="radio"
          value="sealed"
          defaultValue={defaultValue}
          checked={selectedOption === "sealed"}
          onChange={handleOptionChange}
          id={sealedId}
          ref={sealedRef}
        />
        <label className="custom-control-label-passfail" htmlFor={sealedId}>
          Sealed
        </label>
      </div>
      <div className="custom-control custom-radio-passfail option-inline">
        <input
          className="custom-control-input"
          name={name}
          type="radio"
          value="broken"
          defaultValue={defaultValue}
          checked={selectedOption === "broken"}
          onChange={handleOptionChange}
          id={brokenId}
          ref={brokenRef}
        />
        <label className="custom-control-label-passfail" htmlFor={brokenId}>
          Broken
        </label>
      </div>
    </div>
  );
});